import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import classes from './Cell.module.css';

const cx = classNames.bind(classes);

const cellLabels = [
  'top row, left cell',
  'top row, center cell',
  'top row, right cell',
  'middle row, left cell',
  'middle row, center cell',
  'middle row, right cell',
  'bottom row, left cell',
  'bottom row, center cell',
  'bottom row, right cell',
];

function Cell({ cell, currentToken, children, placeToken, noFocus, setNoFocus }) {
  return (
    <button
      type="button"
      aria-label={`${cell.value == null ? 'empty' : cell.value}, ${cellLabels[cell.index]}`}
      className={cx({
        cell,
        empty: cell.value == null,
        shiftToken: cell.value === currentToken,
        // eslint-disable-next-line object-shorthand
        noFocus: noFocus,
        win: cell.win,
        col2: cell.index % 3 === 1,
        col3: cell.index % 3 === 2,
        onlyColWin: cell.onlyColWin,
      })}
      data-current-token={currentToken}
      onMouseDown={() => {
        setNoFocus(true);
      }}
      onClick={() => {
        placeToken(cell.id);
      }}
      onKeyDown={() => {
        setNoFocus(false);
      }}
    >
      <span className={classes.token}>{children}</span>
    </button>
  );
}

Cell.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.oneOf(['', 'X', 'O']),
    id: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    win: PropTypes.bool,
    onlyColWin: PropTypes.bool,
  }).isRequired,
  currentToken: PropTypes.oneOf(['X', 'O']).isRequired,
  children: PropTypes.oneOf(['', 'X', 'O']).isRequired,
  placeToken: PropTypes.func.isRequired,
  noFocus: PropTypes.bool.isRequired,
  setNoFocus: PropTypes.func.isRequired,
};

export default Cell;
