import React from 'react';
import PropTypes from 'prop-types';

import classes from './Layout.module.css';

function Layout({ children }) {
  return (
    <div className={classes.main}>
      <main>{children}</main>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Layout;
