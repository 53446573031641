import React, { useState } from 'react';
import Board from '../components/toe/Board';

import classes from './Toe.module.css';

function ToePage() {
  const initialState = {
    cells: Array.from(Array(9), (_, index) => ({ value: null, id: index, index })),
    currentToken: 'X',
    statusMessage: "X's Turn",
    over: false,
    noFocus: false,
  };
  const [toeState, setToeState] = useState(initialState);

  function setNoFocus(newNoFocusState) {
    setToeState((prevToeState) => ({ ...prevToeState, ...{ noFocus: newNoFocusState } }));
  }

  function calculateEndConditions(newToeState) {
    // store id's of cells that are part of a win line. No duplicates. Either 0, 3, or 5 values
    // There can be multiple win lines. Up to two in a real game.
    const winCellIds = [];
    let winToken;

    const lines = [];
    const colWinCellIds = []; // the Ids of cell which make up a column win

    for (let i = 0; i < 3; i += 1) {
      // rows
      lines.push({
        type: 'row',
        cells: newToeState.cells.filter((cell) => Math.floor(cell.index / 3) === i),
      });
      // columns
      lines.push({
        type: 'col',
        cells: newToeState.cells.filter((cell) => Math.floor(cell.index % 3) === i),
      });
    }

    for (let i = 2; i <= 4; i += 2) {
      // diagonals
      lines.push({
        type: 'diag',
        cells: newToeState.cells.filter((cell) => [4 - i, 4, 4 + i].includes(cell.index)),
      });
    }

    lines.forEach((line) => {
      if (line.cells.every((cell) => cell.value != null && cell.value === line.cells[0].value)) {
        line.cells.forEach((cell) => {
          if (!winCellIds.includes(cell.id)) {
            winCellIds.push(cell.id);
            if (line.type === 'col') {
              colWinCellIds.push(cell.id);
            }
          }
        });
        winToken = line.cells[0].value;
      }
    });

    return {
      isTie: winCellIds.length === 0 && !newToeState.cells.some((cell) => cell.value == null),
      winCellIds,
      winToken,
      colWinCellIds,
    };
  }

  function handleEndConditions(newToeState) {
    const endConditions = calculateEndConditions(newToeState);
    const stateOverrides = {};

    if (endConditions.winCellIds.length !== 0) {
      stateOverrides.statusMessage = `${endConditions.winToken} won!`;
      stateOverrides.over = true;
      stateOverrides.cells = newToeState.cells.map((cell) =>
        endConditions.winCellIds.includes(cell.id)
          ? {
              ...cell,
              win: true,
              onlyColWin:
                endConditions.winCellIds.length === 3 &&
                endConditions.colWinCellIds.includes(cell.id),
            }
          : cell,
      );
    } else if (endConditions.isTie) {
      stateOverrides.statusMessage = "It's a tie :(";
      stateOverrides.over = true;
    }

    return stateOverrides;
  }

  function placeTokenHandler(cellId) {
    setToeState((prevToeState) => {
      if (
        prevToeState.cells.find((cell) => cell.id === cellId)?.value != null ||
        prevToeState.over
      ) {
        return prevToeState;
      }
      const newToken = prevToeState.currentToken === 'X' ? 'O' : 'X';
      const newState = {
        ...{},
        ...prevToeState,
        ...{
          cells: prevToeState.cells.map((cell) =>
            cell.id === cellId ? { ...cell, value: prevToeState.currentToken } : { ...cell },
          ),
          currentToken: newToken,
          statusMessage: `${newToken}'s Turn`,
        },
      };
      const stateOverrides = handleEndConditions(newState);
      return Object.assign(newState, stateOverrides);
    });
  }

  function resetStateHandler() {
    setToeState(initialState);
  }

  return (
    <div className={classes.toe}>
      <h1>React-tac-toe</h1>
      <Board
        cells={toeState.cells}
        currentToken={toeState.currentToken}
        placeToken={placeTokenHandler}
        noFocus={toeState.noFocus}
        setNoFocus={setNoFocus}
        onlyColWin={toeState.onlyColWin}
      />
      <div className={classes.controls}>
        <p className={classes.status} aria-live="polite">
          {toeState.statusMessage}
        </p>
        <button type="button" onClick={resetStateHandler} className={classes.newGame}>
          New Game
        </button>
      </div>
    </div>
  );
}

export default ToePage;
