import React from 'react';
import PropTypes from 'prop-types';

import Cell from './Cell';

import classes from './Board.module.css';

function Board({ cells, currentToken, placeToken, noFocus, setNoFocus }) {
  return (
    <div className={classes.board}>
      {cells.map((cell) => (
        <Cell
          key={cell.id}
          cell={cell}
          currentToken={currentToken}
          placeToken={placeToken}
          noFocus={noFocus}
          setNoFocus={setNoFocus}
        >
          {cell.value != null ? cell.value : ''}
        </Cell>
      ))}
    </div>
  );
}

Board.propTypes = {
  cells: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOf(['', 'X', 'O']),
      id: PropTypes.number.isRequired,
      index: PropTypes.number.isRequired,
      win: PropTypes.bool,
      onlyColWin: PropTypes.bool,
    }),
  ).isRequired,
  currentToken: PropTypes.oneOf(['X', 'O']).isRequired,
  placeToken: PropTypes.func.isRequired,
  noFocus: PropTypes.bool.isRequired,
  setNoFocus: PropTypes.func.isRequired,
};

export default Board;
