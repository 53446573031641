import React from 'react';
import { Route, useHistory } from 'react-router-dom';

import Layout from './layout/Layout';
import ToePage from './pages/Toe';

export default function App() {
  const history = useHistory();
  history.replace('/'); // for any url load the game

  return (
    <Layout>
      <Route path="/">
        <ToePage />
      </Route>
    </Layout>
  );
}
